import {ComponentObjectPropsOptions, PropType} from 'vue';
import type {DensityType} from '~/components/LComponents/Props/DensityType';

type VariantType = "outlined" | "plain" | "solo" | "filled" | "underlined"
type InputType = 'text' | 'number' | 'email' | 'phone' | 'password'

type TextInputPropsType = {
    type: InputType,
    placeholder: string,
    persistentPlaceholder: boolean,
    autofocus: boolean,
    hideDetails: boolean | string,
    variant: VariantType,
    density: DensityType,
    singleLine: boolean,
    style: object,
    suffix: string
    clearable: boolean,
    textCenter: boolean
    class: string,
}
export const TextInputProps: ComponentObjectPropsOptions<TextInputPropsType> = {
  type: {
    default: 'text',
    type: String as PropType<InputType>,
  },
  placeholder: {
    default: '',
    type: String,
  },
  suffix: {
    type: String,
  },
  persistentPlaceholder: {
    default: false,
    type: Boolean,
  },
  autofocus: {
    default: false,
    type: Boolean,
  },
  hideDetails: {
    default: "auto",
    type: [Boolean, String,],
  },
  variant: {
    type: String as PropType<VariantType>,
    default: 'filled',
  },
  density: {
    type: String as PropType<DensityType>,
    default: "default",
    required: false,
  },
  singleLine: {
    default: false,
    type: Boolean,
  },
  class: {
    default: undefined,
    type: String,
  },
  style: {
    default: undefined,
    type: Object,
  },
  clearable: {
    default: false,
    type: Boolean,
  },
  textCenter: {
    default: false,
    type: Boolean,
  },
};
