<template>
  <v-row v-if="label || $slots.appendLabel">
    <v-col>
      <label class="body-1" :class="{'text-error': error,'text-primary': isFocused}" v-if="label" :for="id">
        {{ label }} <span v-if="required" class="text-error">*</span>
      </label>
    </v-col>
    <v-col cols="auto" align="end">
      <slot name="appendLabel"/>
      <!--      <span class="text-right" v-if="tooltip">-->
      <!--        <l-tooltip :id="id">{{tooltip}}</l-tooltip>-->
      <!--      </span>-->
    </v-col>
  </v-row>
  <v-row v-if="subtitle" no-gutters>
    <v-col>
      <p class="body-2 text-medium-emphasis">{{subtitle}}</p>
    </v-col>
  </v-row>
  <v-row v-if="$slots.default" no-gutters v-bind="attrsToBind">
    <v-col>
      <div :class="{'mt-1':label}">
        <slot/>
      </div>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
//Todo jak v-tooltip będzie działał srr to odkomentować
// import LTooltip from "~/components/LComponents/LTooltip.vue";

import { omit } from "lodash-es";

const props = defineProps({
  label: {
    required: false,
    type: String,
  },
  tooltip: {
    required: false,
    type: String,
  },
  subtitle: {
    required: false,
    type: String,
  },
  id: {
    required: true,
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
});

const attrsToBind = computed<Object>(() => {
  const attrs = useAttrs();
  return omit(attrs, 'modelValue');
});
</script>
<style scoped lang="scss">
@import '@/assets/styles/vuetify/base/typography/_mixins.scss';

:deep(.v-field) {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity)) !important;
  border-bottom: unset !important;
  border-radius: 4px;
}
/*TODO: dla selecta ma byc 6px dla inputu ma byc 4px*/
:deep(.v-field__input) {
  @include headline-5;
}
:deep(.v-field__focused, .v-field__overlay) {
  opacity: 0;
}
.input-text-center {
  :deep(input) {
    text-align: center;
  }
}
:deep(.v-field__focused) {
  display: none;
}
:deep(.v-field__prepend-inner) {
  cursor: pointer;
}
:deep(.v-field__append-inner) {
  cursor: pointer;
}
</style>
