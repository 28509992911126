import {ComponentObjectPropsOptions, PropType} from "@vue";

type BaseInputPropsType = {
    label: string,
    id: string,
    required: boolean,
    rules: any[],
    tooltip: string,
    mask: string,
    isSmsAutocomplete: boolean
}
export const BaseInputProps: ComponentObjectPropsOptions<BaseInputPropsType> = {
    label: {
        type: String,
        default: '',
    },
    id: {
        type: String,
        default: () => `l-field-${Math.round(Math.random() * 1_000_000)}`,
    },
    required: {
        type: Boolean,
        default: false,
    },
    rules: {
        default: [],
        type: Array as PropType<any[]>,
    },
    tooltip: {
        required: false,
        type: String,
    },
    mask: {
        required: false,
        type: String,
    },
    isSmsAutocomplete: {
        type: Boolean,
        default: false,
    },
};
